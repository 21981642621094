.fade-in {
    opacity: 0;
    animation: fadeInAnimation 1.2s ease-in forwards;
  }
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes neon-glow {
    0% { text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #00eaff, 0 0 40px #00eaff, 0 0 55px #00eaff, 0 0 75px #00eaff; }
    100% { text-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #00eaff, 0 0 20px #000, 0 0 35px #000, 0 0 50px #000; }
  }

  