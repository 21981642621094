.image-size {
    width: 14rem;
    height: 14rem;
    border: .25rem inset rgba(0, 0, 255, 0.63);
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    opacity: 1;
  }

  .projects {
    display: grid;
    display: flex;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: .2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(207, 204, 204, 0.473);
    border: .125rem inset rgba(0, 0, 255, 0.63);
  } 

  .aframe-project, .love-project {
    box-shadow: 0 0 .75rem rgba(206, 219, 243, 0.829);
  }

  .change-p-text {
    font-family: 'Grandstander', cursive;
    font-weight: bold;
    padding: .2rem;
    text-decoration: underline;
  }

  .arrows-container {
    display: flex;
    padding: .1rem;
  }

  .fa-solid {
    font-size: 2rem;
    color: rgba(20, 20, 255, 0.726);
    margin: .05rem;
  }

  .fa-solid:hover {
    cursor: pointer;
    color: rgba(12, 12, 245, 0.932);
  }

  .images-container {
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Adjust alignment for caption */
    background-color: rgba(255, 255, 255, 0.486);
    width: 70%;
    padding: 1.5rem;
    position: relative; /* Added position relative */
    border: .2rem solid rgba(0, 0, 255, 0.63);
    margin-bottom: .15rem;
    margin-top: .25rem;
  }
  
  .caption {
    position: absolute;
    bottom: 0;
    width: 80%;
    text-align: center;
    padding: .5rem;
    color: white;
    margin: .10rem;
  }

  .love-project .caption{
    border: .12rem solid rgba(175, 11, 11, 0.7);
    background-color: rgba(165, 10, 10, 0.76);
  }

  .aframe-project .caption {
    border: .12rem solid green;
    background-color: rgba(6, 134, 6, 0.829);
  }
  
  .caption h2 {
    margin: 0;
    font-size: .78rem;
    font-family: 'Grandstander', cursive;
  }
  
  .caption p {
    margin: 0;
    font-size: .58rem;
    font-family: 'Grandstander', cursive;
  }
  
 .project-text {
    padding: .2rem;
    font-size: 1.4rem;
    background-color: rgba(255, 255, 255, 0.616);
    border: .13rem solid rgba(4, 4, 245, 0.644);
    text-align: center;
    font-family: 'Grandstander', cursive;
 }

 .projects-container {
    background-color: rgba(86, 102, 238, 0.548);
    border: .20rem solid rgba(36, 36, 226, 0.555);
    margin: .075rem;
 }

 .button {
    border: .1rem solid rgba(253, 252, 252, 0.87);
    background-color: rgba(255, 255, 255, 0.925);
    width: 50%;
 }

 .button-1:hover {
    background-color: rgba(165, 10, 10, 0.76);
    text-decoration: underline;
    color: white;
 }

 .button-2:hover {
    background-color: rgba(6, 134, 6, 0.829);
    text-decoration: underline;
    color: white;
 }

 /* for mobile view */
@media (max-width: 767px) {
    * {
        padding: 0;
        margin: 0;
      }
    .caption {
        font-size: .38rem;
    }
    
    .caption p {
        font-size: .3rem;
    }

    .caption h2 {
        font-size: .5rem;
    }

    .project-text {
        font-size: .8rem;
    }

    .image-size {
        width: 6.5rem;
        height: 6.5rem;
    }
    .projects-container {
        border: .15rem solid rgba(0, 0, 255, 0.63);
    }

    .fa-solid {
      font-size: 1.2rem;
      color: rgba(20, 20, 255, 0.726);
    }

    .change-p-text  {
      font-size: .65rem;
    }
  }