 .image-size-2 {
    width: 13.5rem;
    height: 13.5rem;
    border: .2rem outset rgba(0, 0, 255, 0.63);
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    opacity: 1;
    box-shadow: 0 0 1rem rgba(222, 230, 245, 0.911);
  }

  .App p {
    text-align: center;
  }

  .Message {
    border: .1rem outset rgba(160, 160, 206, 0.63);
    background-color: rgba(130, 183, 226, 0.192);
  }
  
  .Welcome-Text {
    font-family: 'Grandstander', cursive;
    font-size: 1.75rem;
    padding: .15rem;
    animation: neon-glow 90s linear infinite;
  }
  
  .Signiture {
    font-family: 'Cedarville Cursive', cursive;
    font-size: 1.75rem;
      overflow: hidden;
      white-space: nowrap;
  }

  .coding-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    padding: .1rem;
    margin-bottom: .25rem;
    background-color: rgba(255, 255, 255, 0.295);
    box-shadow: 0 0 .75rem  rgba(0, 0, 255, 0.63);
  }

 .sign-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .coding-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px outset rgba(32, 32, 240, 0.384);
    background-color: rgba(156, 154, 154, 0.678);

  }

  .tech-stack-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Mooli', sans-serif;
    box-shadow: 0 0 .75rem  rgba(0, 0, 255, 0.63);
    background-color: rgba(255, 255, 255, 0.295);
    padding: .1rem;
    margin-bottom: .25rem;
  }

  h3 {
    font-size: 1.35rem;
    text-align: center;
    font-weight: bold;
  }
  
  .selfie-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bio-text {
    text-align: center;
    font-size: .9rem;
    background-color: rgba(236, 234, 234, 0.877);
    box-shadow: 0 0 .25rem  rgba(215, 215, 255, 0.63);
    border: .13rem inset rgba(0, 0, 255, 0.63);
    margin: 1.5rem;
    padding: 1rem;
  }

  .bio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .1rem;
  }

  .bottom-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    background-color: rgba(207, 204, 204, 0.473);
    border: .15rem solid rgba(0, 0, 255, 0.63);
    width: 95%; 
  }

  .bottom-container-shell {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: .3rem;
    }
  
  .fa-brands {
    font-size: 2.2rem;
    justify-content: center;
    align-items: center;
    margin: .1rem;
    padding: .05rem;
  }
  
  .fa-react {
    color: rgba(62, 32, 230, 0.795);
  }
  
  .fa-css3-alt {
    color: rgba(255, 238, 0, 0.89);
  }
  
  .fa-html5 {
    color: red;
  }

  .fa-gitlab {
    color: orange;
  }
  
  .fa-js {
    color: rgb(23, 49, 197);
  }
  
  .fa-node {
    color: rgb(11, 128, 40);
  }
  
  /* for mobile view */
  @media (max-width: 767px) {
    .Text-Full {
      font-size: .4rem;
    }
  
    .fa-brands {
      font-size: .75rem; 
    }
  
    .Message {
      border: .1rem solid rgba(0, 0, 255, 0.63);
    }

    .Signiture {
        font-size: .65rem;
    }

    .image-size-2 {
        width: 6.5rem;
        height: 6.5rem;
    }

    p .project-description {
        font-size: .35rem;
    }

      .bio-text {
        text-align: center;
        font-size: .4rem;
        margin: 0rem;
      }

      .coding-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .7rem;
        padding: .1rem;
        width: 12.5rem;
        margin-bottom: .25rem;
        box-shadow: 0 0 .75rem  rgba(0, 0, 255, 0.63);
      }      
  
      h3 {
    font-size: .65rem;
    text-align: center;
  }

  .Welcome-Text {
    font-size: .7rem;
  }
    
  }