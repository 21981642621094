body {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url(./imgs/main-background.png);
  background-position: center;
  background-size: cover; 
  overflow-x: hidden;
}

